<!-- 在库养护 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <!-- 养护人 -->
      <FormItem>
        <span>养护人：</span>
        <Select placeholder="请选择" v-model="queryFrom.user_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in userList" :value="item.id" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <!-- 养护仓库 -->
      <FormItem class="marginRight40">
        <span>养护仓库：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 养护时间 -->
      <FormItem class="marginRight40">
        <span>养护时间：</span>
        <DatePicker :editable="false" type="daterange" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250" @on-change="changeTime"></DatePicker>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="longTextBtn finger btnReset marginLeft20" @click="add">新增养护</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :pages="queryFrom" :total="total" totalText="条记录" @change-page="changePage" :loading="loading" :isLoad="loading"></Table>

    <!-- 新增养护 -->
    <Modal v-model="addMaintenance" :mask-closable="false" width="630" class="modal">
      <p slot="header" class="header">{{ text }}</p>
      <div class="body">
        <!-- 养护仓库 -->
        <div class="item">
          <div class="title">养护仓库：</div>
          <Select placeholder="请输入" v-model="postObj.warehouse_id" clearable filterable class="option" @on-change="wareChange">
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </div>
        <!-- 仓库特性 -->
        <div class="item" v-if="postObj.warehouse_feature">
          <div class="title">仓库特性：</div>
          <div class="option">{{ postObj.warehouse_feature }}</div>
        </div>
        <!-- 养护人 -->
        <div class="item">
          <div class="title">养护人：</div>
          <Select placeholder="请选择" v-model="postObj.user_id" clearable filterable class="option">
            <Option v-for="(item, index) in userList" :value="item.id" :key="index">{{ item.user_name }}</Option>
          </Select>
        </div>
        <!-- 养护时间 -->
        <div class="item">
          <div class="title">养护时间：</div>
          <DatePicker type="date" v-model="postObj.create_time" @on-change="changeTime2" placeholder="请选择养护时间" style="width: 200px;"></DatePicker>
        </div>
        <!-- 储存条件 -->
        <div class="item">
          <div class="title">储存条件：</div>
          <div class="option">
            <div class="fl mr40">温度：<Input v-model="postObj.temperature" placeholder="请输入" type="number" class="width170 mr10"></Input>°C</div>
            <div class="fl" v-if="postObj.warehouse_temperature">温度区间：{{ postObj.warehouse_temperature }}</div>
          </div>
        </div>
        <div class="item">
          <div class="title"></div>
          <div class="option">
            <div class="fl mr40">湿度：<Input v-model="postObj.humidity" placeholder="请输入" type="number" class="width170 mr10"></Input><span style="opacity: 0;">°C</span></div>
            <div class="fl" v-if="postObj.warehouse_humidity">湿度区间：{{ postObj.warehouse_humidity }}</div>
          </div>
        </div>
        <!-- 养护方式 -->
        <div class="item">
          <div class="title">养护方式：</div>
          <Input v-model="postObj.maintenance_method" placeholder="请输入" class="option"></Input>
        </div>
        <!-- 仓库环境 -->
        <div class="item">
          <div class="title">仓库环境：</div>
          <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll" class="marginRight40">全部</Checkbox>
          <CheckboxGroup v-model="postObj.warehouse_env" class="option" @on-change="envChange">
            <Checkbox label="1" class="marginRight40">无尘</Checkbox>
            <Checkbox label="2" class="marginRight40">通风</Checkbox>
            <Checkbox label="3" class="marginRight40">防虫</Checkbox>
            <Checkbox label="4" class="marginRight40">防鼠</Checkbox>
            <Checkbox label="5" class="marginRight40">防潮</Checkbox>
            <Checkbox label="6" class="marginRight40">防火</Checkbox>
            <Checkbox label="7" class="marginRight40">防盗</Checkbox>
          </CheckboxGroup>
          <!-- <CheckboxGroup v-model="postObj.warehouse_env" class="option" @on-change="envChange">
            <Checkbox label="-1" class="marginRight40">全部</Checkbox>
            <Checkbox label="1" class="marginRight40">无尘</Checkbox>
            <Checkbox label="2" class="marginRight40">通风</Checkbox>
            <Checkbox label="3" class="marginRight40">防虫</Checkbox>
            <Checkbox label="4" class="marginRight40">防鼠</Checkbox>
            <Checkbox label="5" class="marginRight40">防潮</Checkbox>
            <Checkbox label="6" class="marginRight40">防火</Checkbox>
            <Checkbox label="7" class="marginRight40">防盗</Checkbox>
          </CheckboxGroup> -->
        </div>
        <!-- 包装是否完好 -->
        <div class="item">
          <div class="title longTitle">包装是否完好：</div>
          <RadioGroup v-model="postObj.is_intact" class="option">
            <Radio label="1" class="marginRight40">是</Radio>
            <Radio label="0">否</Radio>
          </RadioGroup>
        </div>
        <!-- 效期是否>6个月 -->
        <div class="item">
          <div class="title longTitle">效期是否>6个月：</div>
          <RadioGroup v-model="postObj.is_effective" class="option">
            <Radio label="1" class="marginRight40">是</Radio>
            <Radio label="0">否</Radio>
          </RadioGroup>
        </div>
        <!-- 是否受潮 -->
        <div class="item">
          <div class="title">是否受潮：</div>
          <RadioGroup v-model="postObj.is_damp" class="option">
            <Radio label="1" class="marginRight40">是</Radio>
            <Radio label="0">否</Radio>
          </RadioGroup>
        </div>
        <!-- 养护结果 -->
        <div class="item">
          <div class="title">养护结果：</div>
          <RadioGroup v-model="postObj.is_eligibility" class="option">
            <Radio label="1" class="marginRight40">合格</Radio>
            <Radio label="0">不合格</Radio>
          </RadioGroup>
        </div>
        <!-- 整改措施 -->
        <div class="item" style="margin-bottom: 0px;">
          <div class="title">整改措施：</div>
          <Input v-model="postObj.corrective_measures" placeholder="请输入" class="option"></Input>
        </div>
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="post" v-if="text == '新增养护'">确认</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'

export default {
  name: 'maintenance',
  components: {
    Table,
  },
  computed: {},
  data() {
    return {
      loading: true,
      text: '',
      // 新增养护
      addMaintenance: false,
      total: 1,
      listData: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '养护时间',
          key: 'createTime',
          align: 'center',
          width: 150,
        },
        {
          title: '养护仓库',
          key: 'warehouse_name',
          align: 'center',
        },
        {
          title: '仓库特性',
          key: 'warehouse_feature',
          align: 'center',
          minWidth: 200,
        },
        {
          title: '养护人',
          key: 'user_name',
          align: 'center',
        },
        {
          title: '养护结果',
          key: 'result',
          align: 'center',
          width: 150,
        },
        {
          title: '整改措施',
          key: 'corrective_measures',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 189,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      storeList: [],
      userList: [],
      queryFrom: {
        page: 1,
        rows: 10,
        warehouse_id: '',
        user_id: '',
        create_time_start: '',
        create_time_end: '',
      },
      postObj: {
        create_time: '', //养护时间
        warehouse_id: '',
        warehouse_name: '', // 仓库名称
        user_id: '', // 养护人ID
        temperature: '', // 温度
        humidity: '', // 湿度
        maintenance_method: '', // 养护方式
        warehouse_env: [], // 仓库环境 1=无尘 2=通风 3=防虫 4=防鼠 5=防潮 6=防火 7=防盗
        warehouse_feature: '', // 仓库特性 1=常温库 2=阴凉库
        is_intact: '', // 包装是否完好，True=是  False=否
        is_effective: '', // 有效期是否大于6个月，True=是  False=否
        is_damp: '', // 是否受潮，True=是  False=否
        is_eligibility: '', // 养护结果是否合格，True=是  False=否
        corrective_measures: '', // 整改措施
        warehouse_temperature: '',
        warehouse_humidity: '',
      },
      indeterminate: false, //勾选全部显示
      checkAll:false, //是否勾选全部
    }
  },
  mounted() {
    this.query()
    this.queryStoreList()
    this.specialUser()
  },
  methods: {
    changeTime2(e) {
      if (e) {
        this.postObj.create_time = e
      }
    },
    wareChange(e) {
      for (let i = 0; i < this.storeList.length; i++) {
        if (this.storeList[i].warehouse_id == e) {
          this.postObj.warehouse_feature = this.storeList[i].warehouse_character
          this.postObj.warehouse_temperature = this.storeList[i].warehouse_temperature
          this.postObj.warehouse_humidity = this.storeList[i].warehouse_humidity
        }
      }
    },
    envChange(e) {
      console.log(e)
      
      // console.log(e.indexOf('-1'))
      // if (e.indexOf('-1') >= 0) {
      //   this.postObj.warehouse_env = ['-1', '1', '2', '3', '4', '5', '6', '7']
      // }
      //以前全部有个值是-1现在需要兼容一下
      let warehouse_env_arr=['1', '2', '3', '4', '5', '6', '7']
      let warehouse_env_new =[];
      e.forEach(item => {
        if(warehouse_env_arr.includes(item)){
          warehouse_env_new.push(item);
        }
      });
      if (warehouse_env_new.length === 7) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (warehouse_env_new.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
      this.postObj.warehouse_env = warehouse_env_new;
    },
    // 养护人
    specialUser() {
      this.$http.get(this.$api.publicSpecialUser, { user_type: '3' }, true).then(res => {
        this.userList = res.data
      })
    },
    // 添加
    add() {
      this.addMaintenance = true
      this.text = '新增养护'
      this.postObj = {
        create_time: '', //养护时间
        warehouse_id: '',
        warehouse_name: '', // 仓库名称
        user_id: '', // 养护人ID
        temperature: '', // 温度
        humidity: '', // 湿度
        maintenance_method: '', // 养护方式
        warehouse_env: [], // 仓库环境 1=无尘 2=通风 3=防虫 4=防鼠 5=防潮 6=防火 7=防盗
        warehouse_feature: '', // 仓库特性 1=常温库 2=阴凉库
        is_intact: '', // 包装是否完好，True=是  False=否
        is_effective: '', // 有效期是否大于6个月，True=是  False=否
        is_damp: '', // 是否受潮，True=是  False=否
        is_eligibility: '', // 养护结果是否合格，True=是  False=否
        corrective_measures: '', // 整改措施
      }
      this.envChange([]);
    },
    // 提交
    post() {
      if (!this.postObj.warehouse_id) {
        this.$Message.warning('请选择养护仓库再提交')
        return
      }
      if (!this.postObj.create_time) {
        this.$Message.warning('请选择养护时间再提交')
        return
      }
      console.log(this.postObj.user_id)
      if (!this.postObj.user_id) {
        this.$Message.warning('请选择养护人再提交')
        return
      }
      if (!this.postObj.is_eligibility && this.postObj.is_eligibility !== '0') {
        this.$Message.warning('请选择养护结果再提交')
        return
      }
      this.$http.post(this.$api.warehouseMaintenance, this.postObj, true).then(res => {
        this.addMaintenance = false
        this.query()
        this.$Message.success('新增养护成功')
      })
    },
    // 时间改变
    changeTime(e) {
      if (e[0] == '' && e[1] == '') {
        this.queryFrom.create_time_start = null
        this.queryFrom.create_time_end = null
      } else {
        this.queryFrom.create_time_start = e[0]
        this.queryFrom.create_time_end = e[1]
      }
    },
    // 查询仓库列表
    queryStoreList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.loading = true
      this.$http.get(this.$api.warehouseMaintenance, this.queryFrom, true).then(res => {
        this.loading = false
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].createTime = res.data[i].create_time ? this.$moment(res.data[i].create_time * 1000).format('YYYY-MM-DD') : ''
          res.data[i].result = res.data[i].is_eligibility == '1' ? '合格' : '不合格'
        }
        this.listData = res.data
        this.total = res.total
      })
    },
    // 翻页
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    // 详情
    details(row) {
      this.text = '养护详情'
      this.$http.get(this.$api.warehouseMaintenanceDetail, { id: row.id }, true).then(res => {
        this.addMaintenance = true
        this.postObj = res.data
        this.postObj.is_effective = res.data.is_effective + ''
        this.postObj.is_intact = res.data.is_intact + ''
        this.postObj.is_damp = res.data.is_damp + ''
        this.postObj.is_eligibility = res.data.is_eligibility + ''
        this.postObj.warehouse_env = this.postObj.warehouse_env ? this.postObj.warehouse_env : []
        this.postObj.create_time = this.postObj.create_time ? this.$moment(this.postObj.create_time * 1000).format('YYYY-MM-DD'):''
        this.envChange(this.postObj.warehouse_env);
      })
    },
    //勾选全选全不选
    handleCheckAll () {
      if (this.indeterminate) {
          this.checkAll = false;
      } else {
          this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;
      if (this.checkAll) {
          this.postObj.warehouse_env = ['1', '2', '3', '4', '5', '6', '7'];
      } else {
          this.postObj.warehouse_env = [];
      }
    },
  },
}
</script>

<style scoped lang="less">
.modal {
  .header {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
  }

  .body {
    display: flex;
    flex-direction: column;
    .marginRight40 {
      margin-right: 40px;
    }
    .item {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 36px;
      color: #000000;
      display: flex;
      flex-direction: row;
      .title {
        width: 120px;
        text-align: justify;
        text-align-last: justify;
      }
      .longTitle {
        width: 140px !important;
      }

      .option {
        flex: 1;
        .width170 {
          width: 170px;
        }
        .mr10 {
          margin-right: 10px;
        }
        .mr40 {
          margin-right: 40px;
        }
      }
    }
  }
}
</style>
